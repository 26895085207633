var th_ancres = {
    selectors: {
        blocAncre: '.bloc-ancre',
        ancres: '.js-ancres',
        menuAncre: '.js-menu-ancre',
    },
    scrollOffset: 180,
    scrollSpeed: 'slow',

    init: function () {
        th_ancres.ancreClick();
        th_ancres.observer();
    },

    // gestion du scroll smooth
    ancreClick: function () {
        $(th_ancres.selectors.ancres + ' a').each(function () {
            $(this).on('click', function (e) {
                e.preventDefault();
                $('html, body').animate({scrollTop: $($(this).attr('href')).offset().top - th_ancres.scrollOffset}, th_ancres.scrollSpeed);
                return false;
            });
        });
    },

    // callback de l'intersection observer
    obsCallback: function(entries, observer) {
        for (var i = 0; i < entries.length; i++){
            var entry = entries[i];
            var entryId = entry.target.id;

            if(entry.isIntersecting) {
                $(th_ancres.selectors.menuAncre).each(function () {
                    $(this).removeClass('is-active');
                });

                $(th_ancres.selectors.menuAncre + "[href='#" + entryId + "']").addClass('is-active');
            }
        }
    },

    observer: function() {
        if('IntersectionObserver' in window) { // on vérifie si c'est compatible avec le navigateur
            var observer = new IntersectionObserver(th_ancres.obsCallback);

            $(th_ancres.selectors.blocAncre).each(function(){
                observer.observe($(this)[0]);
            })
        }
    },
}
