th_maps.onLoad(function () {

    th_maps.addMarkerIcon('default', {
        iconUrl: '' + document.location.origin + '/wp-content/themes/otsucs/assets/images/markers/ico-marker-map-2x.png',
        shadowUrl: null,
        iconSize: [15, 15],
        iconAnchor: [7, 7]
    });

    th_maps.addMarkerIcon('hover', {
        iconUrl: '' + document.location.origin + '/wp-content/themes/otsucs/assets/images/markers/ico-marker-map-hover-2x.png',
        shadowUrl: null,
        iconSize: [32, 32],
        iconAnchor: [16, 16]
    });

});

th_maps.init(thConfig.map.init);