var carteInteractive = {
    selector: {
        form: "#carte_interactive_facettes",
        map: "#carte_interactive_map",
        btnCloseFiltres: ".close_filtres_carte",
        btnOpenFiltres: "#open_filtres_carte",
        reset: ".reset",
        results: ".result"

    },

    map: null,
    markers: [],
    results: 0,

    init: function () {
        if (document.querySelector(carteInteractive.selector.map)) {
            carteInteractive.loadMap();
            carteInteractive.carteEvent();
        }
    },

    carteEvent: function () {
        // th_overlay_modified.addCallbackOpen(function (overlay_id) {
        //     if (overlay_id === "overlay-carte-interactive") {
        //         var el = $("#" + overlay_id);
        //
        //         if (!el.hasClass("parsed")) {
        //             carteInteractive.loadMap();
        //
        //             el.addClass("parsed");
        //         }
        //
        //         if (isMobileOrSmaller()) {
        //             if (!$(".overlay-carte-interactive__content").hasClass("is-hidden")) {
        //                 $(".overlay-carte-interactive__content").addClass("is-hidden");
        //             }
        //         } else {
        //             $(".overlay-carte-interactive__content").removeClass("is-hidden");
        //         }
        //     }
        // });
        //
        // if (window.location.href.indexOf('#overlay-carte-interactive') !== -1) {
        //     setTimeout(function () {
        //         carteInteractive.loadMap();
        //
        //         $("#overlay-carte-interactive").addClass("parsed");
        //     }, 350);
        // }

        $(carteInteractive.selector.btnCloseFiltres).on("click", function () {
            $(".overlay-carte-interactive__content").removeClass("open");
        });

        $(carteInteractive.selector.btnOpenFiltres).on("click", function () {
            $(".overlay-carte-interactive__content").addClass("open");
        });
    },

    loadMap: function () {
        carteInteractive.map = th_maps.initSingleMap(document.querySelector(carteInteractive.selector.map));
        $.ajax({
            url: "/wp-admin/admin-ajax.php",
            method: "get",
            data: {
                action: "get_carte_interactive"
            }
        })
            .then(function (response) {
                var res = JSON.parse(response);
                carteInteractive.loadPoints(carteInteractive.map, res.data.points);
                carteInteractive.appendHTML(carteInteractive.selector.form, res.data.HTMLInputs);

                carteInteractive.initDropdowns();
                carteInteractive.inputEvents();
                carteInteractive.openByDefault();
                carteInteractive.resetListen();
            });
    },

    loadPoints: function (map, json) {
        $.each(json, function (index) {
            var selection = this.selection_id;
            var icon = null;

            if (this.icon[0] !== undefined) {
                icon = "icon-" + selection;

                th_maps.addMarkerIcon(icon, {
                    className: icon,
                    html: '<div class="marker-pin-actif"><span class="' + this.icon[0] + '"></span></div>',
                    shadowUrl: null,
                    iconSize: [34, 34],
                    iconAnchor: [17, 17]
                });
            }

            $.each(this.points, function () {

                var marker = th_maps.createMarker(null, {
                    lat: this.lat,
                    lng: this.lng
                }, icon ? icon : 'default', 'selection-' + selection);
                var lat = this.lat;
                var lng = this.lng;
                var post_id = this.id;

                carteInteractive.markers.push(marker);

                marker.on('click', function () {
                    if (carteInteractive.lastActiveMarker) {
                        if (icon) {
                            carteInteractive.lastActiveMarker.setIcon(th_maps.markersIcons[icon]);
                        } else {
                            carteInteractive.lastActiveMarker.setIcon(th_maps.markersIcons['default']);
                        }
                        carteInteractive.lastActiveMarker = null;
                    }
                    marker.setIcon(th_maps.markersIcons['hover']);
                    carteInteractive.lastActiveMarker = marker;

                    $.ajax({
                        url: "/wp-admin/admin-ajax.php",
                        method: "POST",
                        data: {
                            action: "get_carte_interactive_point",
                            post_id: post_id
                        },
                        success: function (html) {
                            var response = JSON.parse(html);
                            var content = response.html;

                            var infoWindow = th_maps.createInfoWindow(content);
                            infoWindow.on('remove', function () {
                                if (icon) {
                                    carteInteractive.lastActiveMarker.setIcon(th_maps.markersIcons[icon]);
                                } else {
                                    carteInteractive.lastActiveMarker.setIcon(th_maps.markersIcons['default']);
                                }
                            });
                            infoWindow.setLatLng({lat: lat, lng: lng}).openOn(map);

                            var lazyLoadInstance = new LazyLoad({
                                elements_selector: ".lazy"
                            });
                        }
                    });
                })

            })
        });

        var group = new L.featureGroup(carteInteractive.markers);
        carteInteractive.map.fitBounds(group.getBounds(), {padding: [50, 50]});

        $(carteInteractive.selector.form).on('submit', function (e) {
            e.preventDefault();
            th_overlay_modified.close('overlay-filtres-carte');
        });
    },

    appendHTML: function (container, content) {
        if (container && content) {
            document.querySelector(container).innerHTML = content;
        }
    },

    initDropdowns: function () {

        // accordeon des filtres
        $('.group-checkbox > legend', carteInteractive.selector.form).on("click", function () {
            if (this.parentNode.classList.contains("is-active")) {
                this.parentNode.classList.remove("is-active");
            } else {
                this.parentNode.classList.add("is-active");
            }
        });

        $('.frm_checkbox > label', carteInteractive.selector.form).append('<span></span>');
    },

    inputEvents: function () {
        $("input[type='checkbox']", $(carteInteractive.selector.form)).on("change", function () {
            carteInteractive.showOrhide(this);
        });

        $("input[type='checkbox']", $(carteInteractive.selector.form)).on("force-change", function () {
            carteInteractive.showOrhide(this);
        });
    },

    showOrhide: function (cela) {
        var el = cela;
        setTimeout(function () {
            var nbrInGroup = (typeof th_maps.markersGrouped['selection-' + el.value] !== 'undefined') ? th_maps.markersGrouped['selection-' + el.value].length : 0;
            if (el.checked) {
                th_maps.showGroupMarker('selection-' + el.value, carteInteractive.map);
                carteInteractive.results += nbrInGroup;
            } else {
                th_maps.hideGroupMarker('selection-' + el.value);
                carteInteractive.results -= nbrInGroup;
            }

            carteInteractive.updateResult();
        }, 50);

    },

    resetListen: function () {
        $(carteInteractive.selector.reset, '#overlay-carte-interactive').on('click', function (e) {
            e.preventDefault();
            carteInteractive.reset();
        });
    },

    reset: function () {
        $("input[type='checkbox']:checked", $(carteInteractive.selector.form)).each(function () {
            $(this).prop('checked', false);
            carteInteractive.showOrhide(this);
        });
    },

    updateResult: function () {
        $(carteInteractive.selector.results).html(carteInteractive.results);
    },

    openByDefault: function () {
        $("input[type='checkbox']", $(carteInteractive.selector.form)).each(function () {
            if (this.getAttribute("data-display-default")) {
                $(this).click();
            }
        });
    }
};

carteInteractive.init();