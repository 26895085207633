
// PAGE LISTING SIT
function callbackMapItineraire(macarte) {
    console.log("Ma carte", macarte);
    macarte.zoomControl.setPosition('topright');

    var markers = [];
    $('#mymap', $(macarte._container).parents('.bloc')).each(function () {
        var id = this.getAttribute('data-id');

        var latlng = {lat: this.getAttribute('data-lat') * 1, lng: this.getAttribute('data-lng') * 1};
        console.log(latlng);
        var marker = th_maps.createMarker(macarte, latlng, 'hover');

        macarte.on("click", function (e) {
            marker.setIcon(th_maps.markersIcons['hover']);
        });


        th_maps.createInfoWindow(this.outerHTML, marker, 260);
        markers.push(marker);
    });
    var group = new L.featureGroup(markers);
    macarte.fitBounds(group.getBounds());
    if (isTouchDevice()) {
        macarte.dragging.disable();
    }
}

