// Languages selector
$('.js-lang-dropdown-desktop > a').on("click", function (e) {
    e.preventDefault();
    $(this).next().toggleClass("is-open");
});

if (isIE()) {
    document.getElementsByTagName('body')[0].className += ' ie';
}

if (isNoHover()) {
    document.getElementsByTagName('body')[0].className += ' no-hover';
}

th_accordeon.init();
th_overlay.init();
th_expand.parse();
th_ancres.init();

thVheight.init();


// affichage des overlays possible que a partie de 2s
setTimeout(function () {
    $('body').addClass('overlay-load');
}, 1000);

// Lazyload
var lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy"
});

// Lancement du script Librairie Zoombox
$('.zoombox').zoombox();

/* Detect the scroll of the page down */
document.addEventListener('scroll', function () {
    var st = $(this).scrollTop();

    if (st > 100) {
        $("body").addClass("is-scrolled");
    } else {
        $("body").removeClass("is-scrolled");
    }
});


/* Detect the scroll of the page up */
var lastscrolltop = 0;
var lastIsDirTop = 0;
document.addEventListener('scroll', function () {
    var st = $(document).scrollTop();
    if (st < lastscrolltop && lastIsDirTop == 0) {
        lastIsDirTop = 1;
        $(document.body).addClass('scrolldir-top', true);
    }
    if (st > lastscrolltop && lastIsDirTop == 1) {
        lastIsDirTop = 0;
        $(document.body).removeClass('scrolldir-top', true);
    }
    lastscrolltop = st;
});

$('a figcaption').on('touchstart click', function (e) {
    e.stopPropagation();
    e.preventDefault();
});


// Ajout / suppresion d'une classe quand le mega menu est ouvert
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId == 'overlay-menu') {
        $('body').addClass('has-menu-open');
    }

    // Sur mobile, empêche le menu de niveau 2 d'être affiché à l'ouverture par défaut comme sur desktop (attribut checked sur le 1er item)
    if(window.innerWidth < 600) {
        var inputs = document.querySelectorAll('input[name="nav-primary-choices"]:checked');

        for (var i = 0; i != inputs.length; i++) {
            var input = inputs[i];
            $(input).prop('checked',false).removeAttr("checked");           // double suppression attr et prop pour Chrome
        }
    }
})

th_overlay.addCallbackClose(function (overlayId) {
    if (overlayId == 'overlay-menu') {
        $('body').removeClass('has-menu-open');
    }
})


// Ouvre le megamenu sur le menu dans la barre de nav en desktop
$('.js-btn-menu').on('click', function (e) {

    // ouvre le megamenu sans le hash dans l'url
    if($('.overlay-menu').hasClass('open')) {
        console.log($('#overlay-menu'));
        th_overlay.open('overlay-menu', true, true, false);
    }
});


// Smooth scroll vers la carte - au click sur la miniature de la carte dans la barre sit
$("#carte-anchor").on('click', function (event) {
    if (this.hash !== "") {
        event.preventDefault();
        var hash = this.hash;

        $('html, body').animate({
            scrollTop: $(hash).offset().top - 90
        }, 800, function () {
            window.location.hash = hash;
        });
    }
});

